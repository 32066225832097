import React from "react";
import { Helmet } from "react-helmet-async";

function About() {
  return (
    <div>
      <Helmet>
        <title>เกี่ยวกับเรา - ชื่อเว็บไซต์ของคุณ</title>
        <meta property="og:title" content="เกี่ยวกับเรา - ชื่อเว็บไซต์ของคุณ" />
        <meta
          property="og:image"
          content="https://img.soccersuck.com/images/2024/10/08/GOAL---Blank-WEB---Facebook---20.jpg"
        />
      </Helmet>
      <h1>เกี่ยวกับเรา</h1>
      {/* เนื้อหาอื่นๆ ของหน้าเกี่ยวกับเรา */}
    </div>
  );
}

export default About;
