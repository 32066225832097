import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./components/Home";
import About from "./components/About";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">หน้าหลัก</Link>
              </li>
              <li>
                <Link to="/about">เกี่ยวกับเรา</Link>
              </li>
            </ul>
          </nav>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
