import React from "react";
import { Helmet } from "react-helmet-async";

function Home() {
  return (
    <div>
      <Helmet>
        <title>หน้าหลัก - ชื่อเว็บไซต์ของคุณ</title>
        <meta property="og:title" content="หน้าหลัก - ชื่อเว็บไซต์ของคุณ" />
        <meta
          property="og:image"
          content="https://img.soccersuck.com/images/2024/10/08/I8V3neL7.jpg"
        />
      </Helmet>
      <h1>ยินดีต้อนรับสู่หน้าหลัก</h1>
      {/* เนื้อหาอื่นๆ ของหน้าหลัก */}
    </div>
  );
}

export default Home;
